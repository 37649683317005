import { Typography, Container, useTheme } from '@mui/material';
import { SupportAccordion } from 'app/resources/SupportAccordion';
import { ExtLink } from 'components/Link';
import { SupportAccordionTitle, titleColour } from '../resources/Resources';
import imgLargeScreen from './assets/module_nav_bar_large_screen.png';
import imgHamburger from './assets/hamburger.png';
import imgSmallScreen from './assets/module_nav_bar.png';

export const Faq = () => {
  const theme = useTheme();
  const titleStyle = {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  };
  const titleSize = 'h5';
  return (
    <Container>
      <Typography color={titleColour} fontWeight="bold" variant="h5" align="left" sx={titleStyle}>
        Frequently asked questions
      </Typography>
      <Typography>
        This page includes answers to some common questions about PiP. If your question is not covered here, or if you
        need technical support, please{' '}
        <ExtLink href="https://headspace.org.au/online-and-phone-support/family/partners-in-parenting/enquiry/">
          complete this support form
        </ExtLink>
        .
      </Typography>
      <Typography color={titleColour} fontWeight="bold" variant="h6" align="left" sx={titleStyle}>
        General questions about PiP
      </Typography>
      <SupportAccordion
        title={<SupportAccordionTitle title="1. What is the Partners in Parenting program?" titleSize={titleSize} />}>
        <Typography>
          Partners in Parenting (PiP) is an evidence-based parenting program designed to help build parents’ and carers’
          skills and confidence to support their teenager’s mental health and wellbeing. The parenting strategies
          covered in PiP are based on high-quality research and expert consensus on modifiable parenting factors
          associated with mood and anxiety disorders in adolescents.
        </Typography>
      </SupportAccordion>

      <SupportAccordion title={<SupportAccordionTitle title="2. Who is the program for?" titleSize={titleSize} />}>
        <Typography>
          The program is designed for adult carers of young people (aged around 12 to 18 or secondary-school age) who
          are interested in learning more about how they can support their young person’s mental health. This is not a
          strict age limit, and any carer will be able to access the program via their headspace account. However, it’s
          important to note that PiP content, and its evidence base, is intended for parents of{' '}
          <strong>adolescents</strong>.
          <br />
          <br />
          The program is suitable for both <strong>prevention</strong> (i.e. the young person is not currently
          experiencing a mental health problem) or as an <strong>adjunct to treatment</strong> of a young person’s
          mental health problems like depression and anxiety (i.e. a resource for parents while the young person is also
          receiving support). Although PiP has a focus on supporting mental health, a lot of the content covers more
          general parenting challenges that most parents of teenagers will encounter, for example communication,
          managing strong emotions, boundaries, conflict, and staying involved in the teen’s life while supporting their
          growing independence.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="3. Is the program suitable for families of different cultural backgrounds (including First Nations, multicultural families)?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          Although there has been representation of First Nations, multicultural communities across various design,
          development, piloting, and evaluation projects for PiP over the past 9-10 years, people from First Nations and
          multicultural communities have been underrepresented in evaluations of PiP to date (approximately 5-15%). This
          means that we do not yet know how effective the program would be specifically for families from these
          communities. The individual modules are based on widely accepted and evidence-based parenting strategies that
          support wellbeing in young people. Further consideration of parenting approaches in diverse communities, for
          example featuring collectivist approaches to parenting, are likely future enhancements to the program.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="4. What are the plans for evaluating and continuing to improve the program for families of different cultural backgrounds (including First Nations, multicultural families)?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          headspace is working with Monash University to explore the acceptability and outcomes of the program for
          families of various cultural backgrounds, by inviting adult carers from all Australian communities to take
          part in the program and evaluation. This will be really helpful in informing any future changes to the program
          content and/or the way it is made available. No harmful impacts of participating in the program have been
          identified. The modules are based on widely accepted and evidence-based parenting strategies that support
          wellbeing in young people. We are encouraging parents from all communities to read these FAQs and participate
          in the program if they choose. We are actively seeking to find out whether this program benefits people from
          diverse communities, and how the program can be improved in the future.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="5. Is this program suggesting my parenting can cause my teenager’s mental health problem?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          Absolutely not. There are many factors that contribute to a young person’s risk of mental health problems.
          Many of these factors are outside of our control, for example our genetics, biological sex, family history,
          personality, early life events, and many others. On top of these factors, there are some things that parents
          and families can do that either increase or decrease their young person’s risk of mental health problems. For
          this reason, parents are a great resource for young people–whether that be to reduce their risk of future
          mental health problems, or to support those who are already struggling. Rather than causing mental health
          problems, parents can help to build their teenager’s resilience against these problems. PiP is designed to
          help parents do this.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={<SupportAccordionTitle title="6. How can I access the program?" titleSize={titleSize} />}>
        <Typography>
          You can access PiP via your headspace online account. The program is entirely online and can be accessed from
          most devices and internet browsers (including smartphones).
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={<SupportAccordionTitle title="7. What does the program involve?" titleSize={titleSize} />}>
        <ul>
          <li>
            <Typography>
              PiP offers up to 10 interactive online modules on topics related to parenting and teen mental health,
              which can be completed at your own pace. The modules take around 15-25 mins each.
            </Typography>
          </li>
          <li>
            <Typography>
              Optional online pre-program reflection questions which you can answer to get tailored feedback about your
              parenting strengths and areas you could work on, your parenting confidence, and your young person’s mental
              health. If you complete these questions, you will also be recommended specific modules. This part takes
              around 20-30 minutes.
            </Typography>
          </li>
          <li>
            <Typography>
              At the end of the program, you can choose to complete the follow-up reflection questions again, which will
              also provide feedback about your parenting skills, confidence, and your young person’s mental health,
              including how their anxiety and depression symptoms may have changed since starting the program (if you
              answered these same questions at the beginning). You will also have an opportunity to tell us what you
              thought about the program, to help us improve it. This part takes around 20-30 minutes, and is optional.
            </Typography>
          </li>
        </ul>
      </SupportAccordion>

      <SupportAccordion
        title={<SupportAccordionTitle title="8. What do the modules include? " titleSize={titleSize} />}>
        <Typography>
          Each module covers a specific topic related to parenting teenagers and supporting their mental health. Modules
          include educational information, interactive activities, reflection questions, videos, quizzes, and a
          goal-setting exercise at the end of each module to help you put what you’ve learned into practice.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle title="9. How long will it take to complete the program?" titleSize={titleSize} />
        }>
        <Typography>
          You can choose how many of the 10 modules you complete, and when, so the time it takes is up to you. Each
          module takes around 15-25 minutes to complete. We recommend completing one module per week, for around 1 to 3
          months, to allow you enough time to put your learnings into practice. But it’s up to you–you will continue to
          have access to your program and can come back to it any time.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={<SupportAccordionTitle title="10. Do I have to complete all of the modules?" titleSize={titleSize} />}>
        <Typography>
          No, you can work through as many of the modules as you want, and at your own pace. If you don’t have time to
          complete a module, the program will save your progress so that you can return to it later.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="11. Can I contact someone for support/questions when working through the program?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          Our eheadspace clinicians will be available as usual to support you or answer any questions. You can contact
          us via your headspace account by phone, email, or online chat.
          <br />
          <br />
          You can also raise a support request, including requests for technical support, by{' '}
          <ExtLink href="https://headspace.org.au/online-and-phone-support/family/partners-in-parenting/enquiry/">
            completing this form
          </ExtLink>
          .
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="12. What if I need after hours or crisis support while I’m doing PiP?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          If you are in an emergency situation or need immediate assistance, contact mental health services or emergency
          services on 000. If you need to speak to someone urgently at any time, call Lifeline 13 11 14 or Suicide Call
          Back Service 1300 659 467.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={<SupportAccordionTitle title="13. Who will have access to my personal data?" titleSize={titleSize} />}>
        <Typography>
          Data will be stored as per headspace and PiP Terms and Conditions which you agreed to before accessing the
          program. Please refer to these documents for detailed information about how your data will be accessed and
          stored.
          <br />
          The specific responses you enter into the modules will not be monitored by headspace clinicians.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="14. Will doing this program be enough to improve things for my young person?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          There are many factors that contribute to a young person’s risk of mental health problems, and parenting is
          only one of them. Many factors, such as genetics, or previous life experiences, are outside of our control.
          However, there are also many things that adult carers can do to decrease their young person’s risk of mental
          health problems, and PiP is designed to help parents learn and use these protective parenting strategies.
          Therefore, while doing this program cannot guarantee your young person’s mental health will improve, by
          applying what you learn in PiP, you can increase the likelihood that their mental health will improve, and
          reduce their risk of developing further mental health problems in future.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="15. Is it okay to tell my young person that I’m doing PiP? How can I talk to them about it?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          Yes, you can tell your young person that you’re doing PiP or you can keep it private, it is up to you and what
          you think is best for your situation. You may wish to tell your young person that you are doing a parenting
          program to learn skills and strategies to support them with their mental health and wellbeing. When having
          this conversation, it may be helpful to show them some of the online content, so they have a better
          understanding of the topics covered by PiP. Additionally, you might like to tell them that the PiP program was
          developed with input from many Australian teenagers, and that any information you provide about your family as
          part of this program will be kept confidential.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="16. When should I expect to see improvements if I do this program?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          PiP is designed to improve your confidence with being able to support your young person. Improvements in your
          parenting confidence can take time and will depend on your unique family situation, and so there is no single
          answer to this question. However, research has shown that parents who complete more of the modules and apply
          the strategies regularly, are more likely to see greater improvements in their parenting confidence. For
          example, choosing a goal from each module, and working on it during the week before your next module can
          increase the effectiveness of PiP, so we recommend always trying to complete the goal setting activities at
          the end of each module.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="17. If my young person is receiving support from a headspace clinician or other mental health professional, should I tell them that I’m doing this program?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          If you and your young person are happy to share this information, there is no reason why you shouldn’t tell
          their clinician you are doing PiP. Doing so may also help you to all be ‘on the same page’ when it comes to
          your approach to supporting your young person with their mental health.
        </Typography>
      </SupportAccordion>

      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="18. Can I share this program with others (carers, clinicians, etc.)?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          Yes, anyone with a headspace account is able to freely access PiP. You are welcome to share the program with
          other members of your young person’s care team, as well as to recommend the program to others.
        </Typography>
      </SupportAccordion>
      <Typography color={titleColour} fontWeight="bold" variant="h6" align="left" sx={titleStyle}>
        Troubleshooting and technical questions
      </Typography>
      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="19. I have completed a module, but it’s not showing as complete on my dashboard. Why?"
            titleSize={titleSize}
          />
        }>
        <Typography paragraph>
          Modules will be marked as "complete" when all pages have been viewed and activities have been completed. If
          you think you’ve completed a module but it is not showing as complete, have a look to see if there are any
          pages or activities you have missed. You can see this by opening the navigation bar on the left and looking
          for the green ticks. Pages that are complete will have a green tick, whereas pages that are incomplete or
          partially complete will be grey. Click on any pages with grey ticks to return to them and complete the
          activities.
        </Typography>
        <Typography paragraph>
          If you are using a phone or device with a smaller screen, you may need to click the three lines in the top
          left corner to view the navigation bar. See the screenshots below for what this looks like.
        </Typography>
        <Typography
          paragraph
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& img': { width: '100%', maxWidth: '42rem', alignSelf: 'center' },
          }}>
          On larger screens:
          <img src={imgLargeScreen} alt="screenshot of PiP for large screens highlighting the progress on the left" />
        </Typography>
        <Typography
          paragraph
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& img': { width: 'auto', maxHeight: '24rem', alignSelf: 'center' },
          }}>
          On smaller screens, first click the three lines at the top left to view the navigation bar:
          <img src={imgHamburger} alt="screenshot of mobile PiP highlighting the hamburger menu" />
        </Typography>
        <Typography
          paragraph
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& img': { width: 'auto', maxHeight: '24rem', alignSelf: 'center' },
          }}>
          You should then see this:
          <img src={imgSmallScreen} alt="screenshot of mobile PiP showing the module progress" />
        </Typography>
      </SupportAccordion>
      <SupportAccordion
        title={
          <SupportAccordionTitle
            title="20. What happens if I change my headspace account email address?"
            titleSize={titleSize}
          />
        }>
        <Typography>
          If you change your headspace account email address and you're using the PiP program, we'll need to update your
          email address in the PiP system, which may take up to 3 working days. Once your email is updated, your
          progress will be updated, and you can continue the program where you left off. If you access PiP before we've
          updated your email, your progress will not be saved, and you'll be asked to start again. We'll let you know
          when the update is complete.
        </Typography>
      </SupportAccordion>
    </Container>
  );
};
