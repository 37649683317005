import { SitePage } from 'app/layout';
import { robotsNoIndex } from 'app/helpers';
import { Faq } from 'app/faq/Faq';

function FaqPage() {
  return (
    <SitePage>
      <Faq />
    </SitePage>
  );
}

export default robotsNoIndex(FaqPage);
